import img1 from "../images/Facebook.png";
import img2 from "../images/Twitter.png";
import img3 from "../images/LinkedIn.png";
import img4 from "../images/Dribbble.png";
const fakeSocialImg = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
];

export default fakeSocialImg;
