import achievementImg1 from "../images/Happy_clients.png";
import achievementImg2 from "../images/Trophy.png";
import achievementImg3 from "../images/Crazy_minds.png";
import achievementImg4 from "../images/Running_projects.png";
const fakeAchievement = [
  {
    id: 1,
    img: achievementImg1,
    number: "700+",
    title: "Happy Clients",
  },
  {
    id: 2,
    img: achievementImg2,
    number: "140+",
    title: "Projects Completed",
  },
  {
    id: 3,
    img: achievementImg3,
    number: "25+",
    title: "Crazy Minds",
  },
  {
    id: 4,
    img: achievementImg4,
    number: "75+",
    title: "Running Projects",
  },
];

export default fakeAchievement;
