import React from "react";
import Header from "../Header/Header";
import HeaderMain from "../HeaderMain/HeaderMain";
import Achievement from "../Achivement/Achievement";
import Footer from "../Footer/Footer";
import "./Home.css";
const Home = () => {
  return (
    <section className="home">
      <Header />
      <HeaderMain />
      <Achievement />
      <Footer />
    </section>
  );
};

export default Home;
