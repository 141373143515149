import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../images/LD_Logo.png";
const Header = () => {
  return (
    <header className="header ">
      <div className="container px-0">
        <Navbar expand="lg">
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="" className="logo img-fluid" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav ">
            <Nav className="ml-auto justify-content-center align-items-center">
              {/* <Link to="/">Home</Link> */}
              <ul>
                <li>
                  <a href="#" className="headerLink">
                    Domain
                  </a>
                  <ul>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/domain-registration?plid=577744"
                        target="blank"
                      >
                        Domain Registration
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/domains/bulk-domain-search.aspx?plid=577744"
                        target="blank"
                      >
                        Bulk Registration
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/domain-transfer?plid=577744"
                        target="blank"
                      >
                        Domain Transfer
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/domains/bulk-domain-transfer.aspx?plid=577744"
                        target="blank"
                      >
                        Bulk Transfer
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/domain-backorder?plid=577744"
                        target="blank"
                      >
                        Domain Backorder
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#" className="headerLink">
                    Websites
                  </a>
                  <ul>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/website-builder?plid=577744"
                        target="blank"
                      >
                        Website Builder
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/wordpress?plid=577744"
                        target="blank"
                      >
                        WordPress
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/shopping-cart?plid=577744"
                        target="blank"
                      >
                        Shopping Cart
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#" className="headerLink">
                    Hosting
                  </a>
                  <ul>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/cpanel?plid=577744"
                        target="blank"
                      >
                        cPanel
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/plesk?plid=577744"
                        target="blank"
                      >
                        Plesk
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/wordpress?plid=577744"
                        target="blank"
                      >
                        WordPress
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/business?plid=577744"
                        target="blank"
                      >
                        Business
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/vps?plid=577744"
                        target="blank"
                      >
                        VPS Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/dedicated-server?plid=577744"
                        target="blank"
                      >
                        Dedicated Server
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#" className="headerLink">
                    Security
                  </a>
                  <ul>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/website-security?plid=577744"
                        target="blank"
                      >
                        Website Security
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/ssl?plid=577744"
                        target="blank"
                      >
                        SSL
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/ssl-managed?plid=577744"
                        target="blank"
                      >
                        Managed SSL Service
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/website-backup?plid=577744"
                        target="blank"
                      >
                        Website Backup
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#" className="headerLink">
                    Marketing
                  </a>
                  <ul>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/email-marketing?plid=577744"
                        target="blank"
                      >
                        Email Marketing
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/seo?plid=577744"
                        target="blank"
                      >
                        SEO
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/shopping-cart?plid=577744"
                        target="blank"
                      >
                        Shopping Cart
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#" className="headerLink">
                    Email
                  </a>
                  <ul>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/email?plid=577744"
                        target="blank"
                      >
                        Email
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/online-storage?plid=577744"
                        target="blank"
                      >
                        Online Storage
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.secureserver.net/products/calendar?plid=577744"
                        target="blank"
                      >
                        Calendar
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="https://sso.secureserver.net/?plid=577744"
                    className="headerLink"
                  >
                    Login
                  </a>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
