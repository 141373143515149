import React from "react";
import Search from "../../Search/Search";
import "./HeaderMain.css";
const HeaderMain = () => {
  return (
    <>
      <section className="header-main">
        <div className="container p-0">
          <div className="row">
            <div className="col-lg-12 d-flex flex-wrap align-items-center header_main_div">
              <div className="col-lg-8 col-md-8 col-sm-8">
                <h1>Get Perfect .COMs</h1>
                <h4>Best Converting Domains that rake in the Sales Fast </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pb-5">
              <Search />
            </div>
            <div className="col-lg-12 py-5">
              <div className="px-3">
                <h1 className="trust mb-4">Trusted Web Hosting</h1>
                <a
                  href={`https://www.secureserver.net/products/cpanel?plid=577744`}
                  className="buttonElement"
                  target="_blank"
                >
                  Get It Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderMain;
