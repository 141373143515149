const fakeFooterData = [
  {
    id: 1,
    details: [
      {
        id: 1,
        name: "Features",
        link: "#",
      },
      {
        id: 2,
        name: "Enterprise",
        link: "#",
      },
      { id: 3, name: "Pricing", link: "#" },
    ],
  },
  {
    id: 2,
    details: [
      {
        id: 1,
        name: "Blog",
        link: "#",
      },
      {
        id: 2,
        name: "Help Center",
        link: "#",
      },
      {
        id: 3,
        name: "Contact Us",
        link: "#",
      },
      {
        id: 4,
        name: "Status",
        link: "#",
      },
    ],
  },
  {
    id: 3,
    details: [
      {
        id: 1,
        name: "About Us",
        link: "#",
      },
      {
        id: 2,
        name: "Terms of Service",
        link: "#",
      },
      {
        id: 3,
        name: "Security",
        link: "#",
      },
      {
        id: 4,
        name: "Login",
        link: "https://sso.secureserver.net/?plid=577744",
      },
    ],
  },
];

export default fakeFooterData;
